/*
WebFont.load({
	google: {
		families: ['Ubuntu:300,400,400italic,500,700:latin']
	}
});
*/

jQuery(document).ready(function($) {

	var date = new Date().getTime();
	var ajaxDelay = 10000;

	if (isMain) {
		var c1 = new flipCounter('c1', defaults);
		setInterval(function(){
			$.ajax({
				type : 'POST',
				url : 'getData.php',
				dataType : 'json',
				data: { time : date },
				success : function (result) {
					$("#js__cars").html(result['cars']);
					ps = parseInt(result['newPS']);
					c1.add(ps);
					date = new Date().getTime();
				},
				error : function () {
					console.log("error");
				}
			});
		}, ajaxDelay);

		$('.slider').bxSlider({
			minSlides: 1,
			maxSlides: 8,
			slideWidth: 250,
			slideMargin: 20,
			ticker: true,
			speed: 50000
		});

	} else {
		$("#inps").focus();
		getPSInfo($("#js__allps").html());
		$("#saveData").submit(function(e) {
			$.ajax({
				type: "POST",
				url: "saveData.php",
				data: { ps : $("#inps").val() },
				success: function(data) {
					var oldps = $("#js__allps").html();
					$("#js__allps").html(data);
					$("#js__saveInfo").html("Gespeichert");
					getPSInfo(oldps);
					$("#inps").val("");
				}
			});
			e.preventDefault();
		});

	}

});

function getPSInfo(oldps) {
	$.ajax({
		type: "POST",
		url: "getInfo.php",
		data: { oldps : oldps },
		success: function(data) {
			$("#js__psinfo").html(data);
		}
	});
}
